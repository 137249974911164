<template>
    <div class="w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <SupplierPaymentResult999 v-if="supplierId == 999"></SupplierPaymentResult999>
        <SupplierPaymentResult193 v-if="supplierId == 193"></SupplierPaymentResult193>
        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import SupplierPaymentResult999 from '../components/supplier_payments/999/SupplierPaymentResult999'
    import SupplierPaymentResult193 from '../components/supplier_payments/193/SupplierPaymentResult193'
    export default {
        name: 'SupplierPaymentResult',
        components: {
            Loading,
            SupplierPaymentResult999,
            SupplierPaymentResult193
        },
        data: () => {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState({
                // supplierPaymentInProgress: state => state.supplier_payment.supplierPaymentInProgress,
                // getSupplierPaymentDetailsInProgress: state => state.supplier_payment.getSupplierPaymentDetailsInProgress
            }),
            supplierId() {
                return this.$route.params.supplier_id
            }
        },
        created() {
            this.getSupplier({'supplier_id': this.supplierId})
        },
        mounted() {
            //moduleSupplierPayments.init()
        },
        watch: {
            // supplierPaymentInProgress(inProgress) {
            //     this.loading = inProgress || this.getSupplierPaymentDetailsInProgress;
            // },
            // getSupplierPaymentDetailsInProgress(inProgress) {
            //     this.loading = inProgress || this.supplierPaymentInProgress;
            // }
        },
        mounted() {},
        methods: {
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
        }
    }
</script>