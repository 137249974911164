<template>
  <main class="supplier-payment supplier-payment-193 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Payment to the authorities - property tax Beer Sheva") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/monicipal-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <div class="payment-confirmation-data">

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("ID number") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              045652456
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Invoices") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              564656
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Amount of invoices") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              405.95₪
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Service fee") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              7 ₪ לחשבונית
            </div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Payment sum") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              419.95₪
            </div>
          </div>
          <div class="confirm-action mt-15">
            <button class="primary-btn finish-btn">
              {{ $t("Finished and closed") }}
            </button>
            <p class="text-center weight-700 fs20 mt-2">
              {{ $t("* There is a fee of 7 NIS for each Bezeq invoice") }}
            </p>
          </div>
        </div>
      </div>

      <p class="color-red2 fs30 text-center weight-700 mt-15">
        {{ $t("After verifying the details the transaction can not be canceled!") }}
      </p>
      <p class="color-blue1 fs30 text-center weight-700 mt-5">
        {{ $t("At the end of the payment need to give client receipt") }}
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPaymentResult193"
};
</script>
<style lang="scss" scoped>
  .supplier-payment-193 {
    .payment-caption-container {
      align-items: center;
      .payment-caption-wrapper {
        margin-top: 0;
      }
      .supplier-logo {
        max-width: 90px;
        min-width: unset;
      }
      .payment-product-name {
        font-size: 30px;
      }
    }
    .payment-confirmation-data-block-row,
    .payment-success-data-block-2-columns .payment-success-data-block-row {
      font-size: 20px;
      padding: 0 30px;
    }
  }
</style>